<template>
    <BaseModal
        ref="modal"
        @cancel="cancel"
        v-model="isModalOpen"
        :title="title"
        :subtitle="computedSubtitle"
        :confirmButton="computedConfirmButton"
        :cancelButton="computedCancelButton"
        persistent
        :width="720"
        cardTextClass="px-6"
    >
        <template v-if="logEntry">
            <template v-if="logEntry.rest_verb">
                <label>{{
                    $t("modals.LogDetailsModal.rest_verb.label")
                }}</label>
                <code class="mb-3 d-block">
                    {{ logEntry.rest_verb }}
                </code>
            </template>

            <template v-if="logEntry.message">
                <label>{{ $t("modals.LogDetailsModal.message.label") }}</label>
                <p class="font-weight-bold mx-2">
                    {{ logEntry.message }}<br />
                </p>
            </template>

            <v-divider class="my-3"></v-divider>

            <cp-textarea
                v-if="logEntry.request_text"
                :label="$t('modals.LogDetailsModal.request.label')"
                :value="logEntry.request_text"
                readonly
                rows="3"
                class="code-textarea"
            />
            <cp-textarea
                v-if="logEntry.response_text"
                :label="$t('modals.LogDetailsModal.response.label')"
                :value="logEntry.response_text"
                readonly
                rows="3"
                class="code-textarea"
            />
            <cp-textarea
                v-if="logEntry.misc_text"
                :label="$t('modals.LogDetailsModal.misc.label')"
                :value="logEntry.misc_text"
                readonly
                rows="3"
                class="code-textarea"
            />
        </template>
    </BaseModal>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal'
import BaseModalMixin from '@/components/modals/BaseModalMixin'
import LogEntry from '@/models/LogEntry'

export default {
    name: 'LogDetailsModal',
    components: { BaseModal },
    mixins: [BaseModalMixin],
    data: vm => ({
        title: vm.$t('modals.LogDetailsModal.title'),
        logEntry: null
    }),
    methods: {
        /**
         * Valida se as configurações da modal estão válidas
         * throw error em caso de erro
         *
         * @param {*} config Configurações da modal
         */
        validateConfigs(config = {}) {
            if (!(config.item instanceof LogEntry))
                throw new TypeError(
                    `Expected item to be instance of LogEntry. Received: ${typeof config.item}.`
                )

            if (typeof config.index !== "number")
                throw new TypeError(
                    `Expected index to be a number. Received: ${typeof config.index}.`
                )
        },

        opened() {
            this.logEntry = this.currentConfigs.item
        },

        /**
         * É executado antes de limpar
         *
         * @returns {boolean} true quando deve continuar com a limpeza
         */
        async beforeClear() {
            this.logEntry = null
            return true
        }
    },
    computed: {
        computedConfirmButton() {
            return {
                hide: true
            }
        },
        computedCancelButton() {
            return {}
        },

        computedSubtitle() {
            if (!this.logEntry) return ''

            return (
                this.$options.filters.datetimeS(this.logEntry.created_at) +
                ' | ' +
                this.logEntry.severity
            )
        }
    }
}
</script>

<style lang="scss" scoped>
.code-textarea {
    font-family: "Courier New", Courier, monospace
}
</style>