var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseCrudTable", {
    ref: "table",
    attrs: {
      tableName: "LogEntryTable",
      headerConfigs: _vm.headerConfigs,
      fetchFunction: _vm.fetchFunction,
      pagination: _vm.pagination,
      createBtnHidden: true,
      showActions: false
    },
    on: {
      "update:loading": function(value) {
        return (_vm.loading = value)
      }
    },
    scopedSlots: _vm._u([
      {
        key: "item",
        fn: function(props) {
          return [
            _c(
              "LogEntryTableItem",
              _vm._b(
                {
                  attrs: { clickable: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("selected", props)
                    }
                  }
                },
                "LogEntryTableItem",
                props,
                false
              )
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }