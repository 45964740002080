var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: {
        title: _vm.title,
        subtitle: _vm.computedSubtitle,
        confirmButton: _vm.computedConfirmButton,
        cancelButton: _vm.computedCancelButton,
        persistent: "",
        width: 720,
        cardTextClass: "px-6"
      },
      on: { cancel: _vm.cancel },
      model: {
        value: _vm.isModalOpen,
        callback: function($$v) {
          _vm.isModalOpen = $$v
        },
        expression: "isModalOpen"
      }
    },
    [
      _vm.logEntry
        ? [
            _vm.logEntry.rest_verb
              ? [
                  _c("label", [
                    _vm._v(
                      _vm._s(_vm.$t("modals.LogDetailsModal.rest_verb.label"))
                    )
                  ]),
                  _c("code", { staticClass: "mb-3 d-block" }, [
                    _vm._v(" " + _vm._s(_vm.logEntry.rest_verb) + " ")
                  ])
                ]
              : _vm._e(),
            _vm.logEntry.message
              ? [
                  _c("label", [
                    _vm._v(
                      _vm._s(_vm.$t("modals.LogDetailsModal.message.label"))
                    )
                  ]),
                  _c("p", { staticClass: "font-weight-bold mx-2" }, [
                    _vm._v(" " + _vm._s(_vm.logEntry.message)),
                    _c("br")
                  ])
                ]
              : _vm._e(),
            _c("v-divider", { staticClass: "my-3" }),
            _vm.logEntry.request_text
              ? _c("cp-textarea", {
                  staticClass: "code-textarea",
                  attrs: {
                    label: _vm.$t("modals.LogDetailsModal.request.label"),
                    value: _vm.logEntry.request_text,
                    readonly: "",
                    rows: "3"
                  }
                })
              : _vm._e(),
            _vm.logEntry.response_text
              ? _c("cp-textarea", {
                  staticClass: "code-textarea",
                  attrs: {
                    label: _vm.$t("modals.LogDetailsModal.response.label"),
                    value: _vm.logEntry.response_text,
                    readonly: "",
                    rows: "3"
                  }
                })
              : _vm._e(),
            _vm.logEntry.misc_text
              ? _c("cp-textarea", {
                  staticClass: "code-textarea",
                  attrs: {
                    label: _vm.$t("modals.LogDetailsModal.misc.label"),
                    value: _vm.logEntry.misc_text,
                    readonly: "",
                    rows: "3"
                  }
                })
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }