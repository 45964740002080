<template>
    <BaseCrudTable 
        ref="table"
        tableName="LogEntryTable"
        :headerConfigs="headerConfigs"
        :fetchFunction="fetchFunction"
        :pagination="pagination"
        :createBtnHidden="true"
        :showActions="false"
        @update:loading="value => loading = value"
    >
        <template #item="props">
            <LogEntryTableItem clickable @click="$emit('selected', props)" v-bind="props"/>
        </template>
    </BaseCrudTable>
</template>

<script>
import BaseCrudTable        from '@/components/tables/BaseCrudTable'
import LogEntryTableItem from './LogEntryTableItem'

export default {
    name: 'LogEntryTable',
    props: {
        fetchFunction: {
            type: Function,
            required: true,
        },
    },
    components: { BaseCrudTable, LogEntryTableItem },
    data: () => {
        return {
            pagination: {
                page    : 1,
                per_page: 5,
            },
            headerConfigs: [
                {
                    value: 'created_at', 
                    showOnMobile: true,
                },
                {
                    value: 'message',
                    showOnMobile: true,
                },
                {
                    value: 'severity',
                    showOnMobile: false,
                },
                // {
                //     value: 'request',
                //     showOnMobile: false,
                // },
                // {
                //     value: 'response',
                //     showOnMobile: false,
                // },
            ],
            loading: false,
        }
    },
    methods: {
        async refresh() {
            await this.$refs.table.preFetchItems()
        },
    },
}
</script>