<template>
    <v-container fluid class="pt-0">
        <span class="pl-5 pl-md-12 section-title">{{ $t('views.logs.List.title') }}</span>
        <v-alert v-if="hasError" type="error" class="mt-4 mx-5 mt-md-6 mx-md-12">
            {{ $t('errors.generic') }}
        </v-alert>
        <LogEntryTable 
            v-else
            ref="table"
            class="my-4 mx-2 mx-md-10"
            :fetchFunction="fetchFunction"
            @refresh="refreshTable"
            @selected="viewLogEntry"
        />

        <LogDetailsModal ref="log_details_modal"/>
    </v-container>
</template>

<script>
import LogEntryTable        from '@/components/tables/LogEntryTable'
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import LogEntry             from '@/models/LogEntry'
import LogDetailsModal      from './LogDetailsModal'

export default {
    data: () => ({
        hasError        : false,
        interval        : null,
        REFRESH_INTERVAL: 5000,
    }),
    mixins: [ HasErrorHandlerMixin ],
    components: { LogEntryTable, LogDetailsModal },
    async created() {
        // Vai atualizar a tabela a cada intervalo fixo
        this.interval = setInterval(async () => { await this.refreshTable() }, this.REFRESH_INTERVAL)
    },
    beforeDestroy() {
        clearInterval(this.interval)
    },
    methods: {
        async refreshTable() {
            this.$refs.table && (await this.$refs.table.refresh())
        },
        async fetchFunction(pagination) {
            this.hasError = false
            let response  = await LogEntry.list(pagination)
                .catch(this.preErrorHandler)
            return LogEntry.hydrate(this.$lodash.get(response, 'data', []))
        },
        preErrorHandler(e) {
            this.hasError = true
            this.errorHandler(e)
        },
        async viewLogEntry(props) {
            await this.$refs.log_details_modal.open(props)
        },
    },
}
</script>