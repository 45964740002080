import BaseModel from '@/models/BaseModel'
import axios from '@/services/axios'
import i18n from '@/i18n'
import _ from 'lodash'

export default class LogEntry extends BaseModel {

    defaults() {
        return {
            created_at   : undefined,
            message      : undefined,
            severity     : 'log',
            integration  : undefined,
            originalError: undefined,
            shouldNotify : false,
            misc         : undefined,

            request: {
                url    : undefined,
                data   : undefined,
                method : undefined,
                headers: undefined,
            },
            response: {
                status : undefined,
                message: undefined,
                data   : undefined,
            },
        }
    }

    // Métodos úteis

    // Métodos da API

    /**
     * Faz uma requisição para listar as entradas de log
     *
     * @param {object} pagination parâmetros de paginação
     */
    static async list(pagination = {}) {
        let params = {
            page    : pagination.page     || 1,
            per_page: pagination.per_page || pagination.perPage || 10,
            order   : pagination.order    || 'DESC',
        }

        let response = await axios({
            url   : `/log`,
            method: 'get',
            params,
        })

        if (!response)
            return

        return response
    }

    // Getters & Setters

    get severity_i18n() {
        const definedSeverities = [
            'log',
            'debug',
            'error',
            'warning',
        ]

        let mapKey = definedSeverities.includes(this.severity) ? this.severity : 'UNKNOWN'

        return i18n.t(`models.LogEntry.severity.${mapKey}`)
    }

    get severity_color() {
        const colorMapping = {
            'log'    : 'info',
            'debug'  : 'info',
            'error'  : 'error',
            'warning': 'warning',
        }

        return colorMapping[this.severity] || 'info'
    }

    get severity_icon() {
        return null
        // [TODO]
        // const colorMapping = {
        //     'log'    : 'cp_info',
        //     'debug'  : 'cp_info',
        //     'error'  : 'cp_error',
        //     'warning': 'cp_warning',
        // }

        // return colorMapping[this.severity] || 'cp_info'
    }

    get request_text() {
        return !_.isEmpty(this.request) ? JSON.stringify(this.request) : null
    }

    get response_text() {
        return !_.isEmpty(this.response) ? JSON.stringify(this.response) : null
    }

    get misc_text() {
        return JSON.stringify(this.misc)
    }

    get rest_verb() {
        if (!this.request || !this.request.method || !this.request.url)
            return ''

        return `${this.request.method} ${this.request.url}`
    }

}