<template>
    <tr v-on="{ click: clickable ? onClick : () => null }" :class="clickable ? 'clickable' : ''">
        <td class="py-2">
            <div v-if="item.created_at">{{ item.created_at | datetimeS }}</div>
        </td>
        <td class="py-2">{{ item.message }}</td>
        <td class="py-2">
            <v-chip label :color="item.severity_color" outlined small>
                <v-icon small left v-if="item.severity_icon">{{ item.severity_icon }}</v-icon>
                {{ item.severity_i18n }}
            </v-chip>
        </td>
        <!-- <td class="py-2">{{ item.request }}</td>
        <td class="py-2">{{ item.response }}</td> -->
    </tr>
</template>
<script>
export default {
    name: 'ImportEntryTableItem',
    props: {
        item : { type: Object, required: true },
        index: { type: Number, required: true },
        clickable: Boolean,
    },
    methods: {
        onClick() {
            this.$emit('click')
        },
    },
}
</script>