var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tr",
    _vm._g(
      { class: _vm.clickable ? "clickable" : "" },
      {
        click: _vm.clickable
          ? _vm.onClick
          : function() {
              return null
            }
      }
    ),
    [
      _c("td", { staticClass: "py-2" }, [
        _vm.item.created_at
          ? _c("div", [
              _vm._v(_vm._s(_vm._f("datetimeS")(_vm.item.created_at)))
            ])
          : _vm._e()
      ]),
      _c("td", { staticClass: "py-2" }, [_vm._v(_vm._s(_vm.item.message))]),
      _c(
        "td",
        { staticClass: "py-2" },
        [
          _c(
            "v-chip",
            {
              attrs: {
                label: "",
                color: _vm.item.severity_color,
                outlined: "",
                small: ""
              }
            },
            [
              _vm.item.severity_icon
                ? _c("v-icon", { attrs: { small: "", left: "" } }, [
                    _vm._v(_vm._s(_vm.item.severity_icon))
                  ])
                : _vm._e(),
              _vm._v(" " + _vm._s(_vm.item.severity_i18n) + " ")
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }